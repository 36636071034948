<template>
  <div>
    <van-overlay :show="visible" z-index="99" @click="onClose">
      <div class="wrapper" @click.stop>
        <van-icon name="close" class="close-icon" @click="onClose"/>
        <video id="video-player" :src="url" controls class="my-video"></video>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'

export default {
  name: 'MyVideoPlayer',
  components:{ videoPlayer },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props:{
    url: {
      type: String,
      default: '',
    },
    visible:{
      type:Boolean,
      default: false
    }
  },
  data(){
    return {
      
    }
  },
  methods:{
    onClose(){
      // 停止播放视频
      const videoPlayer = document.getElementById('video-player')
      if (videoPlayer) videoPlayer.pause()
      this.$emit('change',false)
    }
  }
}
</script>
<style lang="less" scoped>
.wrapper{
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px;
  .close-icon{
    position: absolute;
    font-size:32px;
    color: #f0f0f0;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  .my-video{
    width: 100%;
    height: calc(100% - 32px)
  }
}
</style>